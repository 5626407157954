import React from "react"
import { graphql, Link } from "gatsby"
import type { HeadFC } from "gatsby"
import { SEO, TwoColumns, MemberCard } from "../components"
import { DefaultLayout } from "../layouts"
import { MemberInterface } from "../typings/member.interface"

type MembersProps = {
    data: {
        members: {
            nodes: MemberInterface[]
        }
        alumnus: {
            nodes: MemberInterface[]
        }
    }
}

const MembersPage = (props: MembersProps) => {
    const members = props.data.members.nodes
    const alumnus = props.data.alumnus.nodes

    return (
        <DefaultLayout>
            <div className="text-center my-10">
                <h1 className="font-inconsolata text-4xl leading-tight md:leading-normal text-semibold">
                    Our Awesome Active Members
                </h1>
                <p className="mb-5 font-inconsolata">
                    Siapa orang-orang di balik CCUG?
                </p>
            </div>

            <div className="flex flex-wrap">
                {members.map((member, index) => {
                    return <MemberCard key={index} {...member} />
                })}
            </div>

            <div className="text-center my-10">
                <h1 className="font-inconsolata text-4xl leading-tight md:leading-normal text-semibold">
                    Meet Our Amazing Alumni
                </h1>
                <p className="mb-5 font-inconsolata">
                    Kami ada hingga sekarang berkat jasa dan kontribusi mereka.
                    :)
                </p>
            </div>
            <div className="flex flex-wrap -mx-4">
                {alumnus.map((alumni, index) => {
                    return <MemberCard key={index} {...alumni} />
                })}
            </div>
        </DefaultLayout>
    )
}

export const MembersPageQuery = graphql`
    query MembersPageQuery {
        members: allMembersYaml(
            filter: {
                username: { ne: "CCUG" }
                role: { regex: "/^(?!.*Alumni)/" }
            }
        ) {
            nodes {
                id
                name
                role
                specialists
                username
                bio
                social_media {
                    link
                    name
                }
                image {
                    publicURL
                }
            }
        }
        alumnus: allMembersYaml(
            filter: { username: { ne: "CCUG" }, role: { regex: "/Alumni/" } }
        ) {
            nodes {
                id
                name
                role
                specialists
                username
                bio
                social_media {
                    link
                    name
                }
                image {
                    publicURL
                }
            }
        }
    }
`

export default MembersPage
export const Head: HeadFC = () => <SEO title="Members" />
